<template>
  <div class="mypage_wrap">
    <div class="mypage_top">
      비밀번호인증<router-link to="/mypage/my_info"
        ><img src="@/assets/images/sub/back_ico.png" alt=""
      /></router-link>
    </div>
    <div class="mypage_in">
      <div class="box_1">
        회원님의 개인정보 보호를 위해<br />비밀번호를 입력해주세요.
      </div>
      <ValidationObserver ref="obServer">
        <form @submit.prevent="regist">
          <ValidationProvider
            :rules="{
              required: true,
            }"
            v-slot="{ errors }"
            name="비밀번호"
          >
            <div class="box_2">
              <input
                type="password"
                name="password"
                maxlength="20"
                v-model="password"
              />
              <p v-if="errors[0]" class="error-txt">{{ errors[0] }}</p>
            </div>
          </ValidationProvider>
          <div class="box_3">
            <router-link to="" @click.native="regist">다음으로</router-link>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      password: null,
    };
  },
  created() {
    this.$store.dispatch("common/setFooter", {
      mypage: true,
    });
  },
  computed: {
    ...mapState("mypage", ["msg", "result", "myInfo", "checkAuth"]),
  },
  methods: {
    async regist() {
      const validate = await this.$refs.obServer.validate();
      if (validate) {
        try {
          await this.$store.dispatch("mypage/authCheck", this.password);

          if (!this.result) {
            this.$toast.default(this.msg);
          } else {
            this.$router.push("/mypage/myInfo_setting");
          }
        } catch (error) {
          console.log("AuthCheck error :", error);
        }
      }
    },
  },
};
</script>

<style></style>
